<template>
    <div class="md:ml-8 xl:ml-4 2xl:ml-8">
        <div v-if="state.isLoaded === true && broadcasts">
            <div v-for="broadcast in broadcasts" :key="broadcast.id" class="col-span-full mb-5">
                <SteamBroadcastCard :broadcast="broadcast" :timeseries="broadcast.timeseries" />
            </div>
        </div>

        <div v-else-if="state.isLoaded === false && broadcasts === null">
            <div class="mb-16">
                <div class="mb-2 h-12 w-full animate-pulse rounded bg-slate-200" />
                <div class="mb-2 h-64 w-full animate-pulse rounded bg-slate-200" />
                <div class="h-6 w-full animate-pulse rounded bg-slate-200" />
            </div>
            <div class="mb-16">
                <div class="mb-2 h-12 w-full animate-pulse rounded bg-slate-200" />
                <div class="mb-2 h-64 w-full animate-pulse rounded bg-slate-200" />
                <div class="h-6 w-full animate-pulse rounded bg-slate-200" />
            </div>
        </div>

        <div v-else-if="(state.isLoaded === true && broadcasts === false) || state.isError">
            <div class="my-16 px-4 text-center">
                <div
                    class="mb-4 inline-flex h-16 w-16 items-center justify-center rounded-full bg-gradient-to-t from-slate-200 to-slate-100"
                >
                    <svg class="h-6 w-5 fill-current" viewBox="0 0 20 24">
                        <path class="text-slate-500" d="M10 10.562l9-5-8.514-4.73a1 1 0 00-.972 0L1 5.562l9 5z" />
                        <path class="text-slate-300" d="M9 12.294l-9-5v10.412a1 1 0 00.514.874L9 23.294v-11z" />
                        <path class="text-slate-400" d="M11 12.294v11l8.486-4.714a1 1 0 00.514-.874V7.295l-9 4.999z" />
                    </svg>
                </div>
                <h2 class="mb-2 text-2xl font-bold text-slate-800">No Report Found</h2>
                <div class="mb-6">Looks like we aren't able to find a report for this campaign.</div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import SteamBroadcastCard from '@/partials/analytics/SteamBroadcastCard.vue'
import reportsAPI from '@/utils/api/api.reports'

export default {
    name: 'CampaignReportSteamSubpage',

    components: {
        SteamBroadcastCard,
    },

    props: {
        supercampaign: Object,
        campaignsById: Object,
    },

    data() {
        return {
            broadcasts: null,
            timeseries: null,

            state: {
                isLoaded: false,
                isError: false,
            },
        }
    },

    created() {
        this.getReport()
    },

    computed: {
        ...mapGetters(['getFromDictionary']),

        campaign() {
            return this.campaignsById[this.$route.params.campaign_id]
        },
    },

    methods: {
        async getReport() {
            const campaignId = this.$route.params.campaign_id
            this.state.isLoaded = false

            const paging = {
                sortby: '',
                sortdir: '',
                size: 100,
                page: 1,
            }

            const reportResult = await reportsAPI.getCampaignReport(
                this.$axios,
                this.supercampaign.id,
                campaignId,
                'steam',
                paging,
            )

            if (reportResult.success) {
                const report = reportResult.value

                if (report?.broadcasts?.length > 0) {
                    this.broadcasts = _.map(report.broadcasts, (broadcast) => {
                        broadcast.timeseries = report.timeseriesBySteamId[broadcast._id]
                        return broadcast
                    })
                } else {
                    this.broadcasts = false
                }

                this.state.isLoaded = true
            } else {
                this.$errorHandler(null, {
                    title: `Campaign Report`,
                    message: `Error fetching report for campaign.`,
                })
            }
        },
    },
}
</script>
